import React from 'react';
import {connect} from "react-redux";
import HeaderListActions from "../redux/actions/header-actions";
import MobileNavbar from "../components/Header/MobileNavbar";

class MobileNavbarContainer extends React.Component {

    render() {
        const setMobileMenu = active => {
       return this.props.setMobileMenu(active)
    };
        return <MobileNavbar mobileActive={this.props.header.mobileMenu} setMobileMenu={setMobileMenu} />
    }
}

let mapStateToProps = (state) => {
    return {
        header : state.header
    }
};
let mapDispatchToProps = (dispatch) => {
    return {
        setMobileMenu: (active) => {
            dispatch (HeaderListActions.setMobileMenu(active));
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileNavbarContainer);