import React from 'react';
import {NavLink} from "react-router-dom";

import './Footer.scss';

const Footer = (props) => {
    return (
        <footer className={"Footer " + props.mobileActive()}>
                <div className="Footer__row">
                    <div className="Footer__logo_column">
                        <span className="Footer__logo"></span>
                        <span className="Footer__logoText">
                            Cтудия дизайна и разработки веб-сайтов
                        </span>
                    </div>
                    <div className="Footer__menu_column">
                        <nav className="Footer__menu_nav" id="sitemap">
                            <ul className="Footer__menuItems">
                                <li className="Footer__menuItem">
                                    <NavLink className="Footer__menuLink" to="/">Главная</NavLink>
                                </li>
                                <li className="Footer__menuItem">
                                    <NavLink className="Footer__menuLink" to="/services">Услуги</NavLink>
                                </li>
                                <li className="Footer__menuItem">
                                    <NavLink className="Footer__menuLink" to="/projects">Наши работы</NavLink>
                                </li>
                                <li className="Footer__menuItem">
                                    <NavLink className="Footer__menuLink" to="/inspire">Интересное</NavLink>
                                </li>
                                <li className="Footer__menuItem">
                                    <NavLink className="Footer__menuLink" to="/contact">Контакты</NavLink>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    <div className="Footer__social_column">
                        <ul id="social" className="Footer__socialItems">
                            <li className="Footer__socialItem">
                                <a className="Footer__socialIcon" href="https://www.behance.net/bkeywebstudio">
                                    <i className="fab fa-behance"></i>
                                </a>
                            </li>
                            <li className="Footer__socialItem">
                                <a className="Footer__socialIcon" href="https://instagram.com/bkeywebstudio/">
                                    <i className="fab fa-instagram"></i>
                                </a>
                            </li>
                            <li className="Footer__socialItem">
                                <a className="Footer__socialIcon" href="https://www.facebook.com/bkeywebstudio/">
                                    <i className="fab fa-facebook-square"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="Footer__contact_column">
                        <a className="Footer__mobileNumber" href="tel:+380680674747" alt="+38 068 067 4747">+38 068 067 4747</a>
                        <a className="Footer__email" href="mailto:info@bkey.com" alt="info@bkey.com.ua">info@bkey.com.ua</a>
                    </div>
                </div>
                <div className="Footer__copyright">© 2020 BKEY Design Web Studio, Inc.</div>
        </footer>
    )
}
export default Footer;
