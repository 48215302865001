import React from 'react';
import {NavLink} from "react-router-dom";

import './Service.scss';
import service from './img/1.jpg'

const Service = () => {
    return (
        <div className="Service">
            <div className="Service__row">
                <div className="Service__img_column">
                    <img src={service} alt="ServicesPromotion" className="Service__img"/>
                </div>
                <div className="Service__text_column">
                    <h3 className="Service__title">Услуги</h3>
                    <p className="Service__text">1. Брендинг | Дизайн (Brand Identity) - создание фирменного стиля компании.</p>
                    <p className="Service__text">2. Разработка сайтов (сайт-визитка, лендинг, корпоративный сайт)</p>
                    <p className="Service__text">3. Интернет-маркетинг: SEO-оптимизация (продвижение сайта), контекстная
                        реклама Google, SMM-продвижение в социальных сетях, создание Viber,
                        Telegram ботов</p>
                    <div className="Service__button">
                        <NavLink to="/services" className="Service__link">Детальнее</NavLink>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Service;
