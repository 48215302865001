import React from 'react';
import {NavLink} from "react-router-dom";

import './ProjectsDesc.scss';

import phone from './img/phone.svg';

const ProjectsDesc = (props) => {
    return (
        <div className="ProjectsDesc">
            <div className="ProjectsDesc__row">
                <div className="ProjectsDesc__text_column">
                    <h2 className="ProjectsDesc__title">Проекты</h2>
                    <p className="ProjectsDesc__text">Идеальный проект не зависит от тематики и вида отрасли, для которого он
                        создаётся. Самое главное - это уникальность, оптимизация и высокая
                        производительность.</p>
                    <div className="ProjectsDesc__button">
                        <NavLink to="/projects" className="ProjectsDesc__link">Детальнее</NavLink>
                    </div>
                </div>
                <div className="ProjectsDesc__img_column">
                    <img src={phone} className="ProjectsDesc__img" alt="phone"/>
                </div>
            </div>
        </div>
    )
};

export default ProjectsDesc;