export const SET_COUNTER = 'SET-COUNTER';
export const SET_COUNTER_NUM = 'SET-COUNTER-NUM';
export const SET_COUNTER_VALUE = 'SET-COUNTER-VALUE';
export const SET_COUNTER_SPEED = 'SET-COUNTER-SPEED';

const CounterActions = {
    setCounter: active => ({
        type: SET_COUNTER,
        payload: active
    }),
    setCounterNum: num => ({
        type: SET_COUNTER_NUM,
        payload: num
    }),
    setCounterValue: value => ({
        type: SET_COUNTER_VALUE,
        payload: value
    }),
    setCounterSpeed: speed => ({
        type: SET_COUNTER_SPEED,
        payload: speed
    }),
};

export default CounterActions;
