import React from 'react';
import MobileNavbarContainer from "./../../containers/mobileNavbar-container";
import {NavLink} from "react-router-dom";

import './Header.scss';

const Header = () => {
    const checkActive = (match, location) => {
        //some additional logic to verify you are in the home URI
        if (!location) return false;
        const {pathname} = location;
        //console.log(pathname);
        return pathname === "/";
    }
    return (
        <header className="Header">
            <NavLink className="Header__logo" to="/" title="Home"></NavLink>
            <nav className="Header__menu">
                <ul className="Header__navigationItems">
                    <li className="Header__navigationItem">
                        <NavLink className="Header__navigationLink" isActive={checkActive} to="/">Главная</NavLink>
                    </li>
                    <li className="Header__navigationItem">
                        <NavLink className="Header__navigationLink" to="/services">Услуги</NavLink>
                    </li>
                    <li className="Header__navigationItem">
                        <NavLink className="Header__navigationLink" to="/projects">Наши работы</NavLink>
                    </li>
                    <li className="Header__navigationItem">
                        <NavLink className="Header__navigationLink" to="/inspire">Интересное</NavLink>
                    </li>
                    <li className="Header__navigationItem">
                        <NavLink className="Header__navigationLink" to="/contact">Контакты</NavLink>
                    </li>
                </ul>
                <ul className="Header__socialItems">
                    <li className="Header__socialItem">
                        <a className="Header__socialIcon" href="https://www.facebook.com/bkeywebstudio/">
                            <i className="fab fa-facebook-square"></i>
                        </a>
                    </li>
                    <li className="Header__socialItem">
                        <a className="Header__socialIcon" href="https://instagram.com/bkeywebstudio/">
                            <i className="fab fa-instagram"></i>
                        </a>
                    </li>
                    <li className="Header__socialItem">
                        <a className="Header__socialIcon" href="https://www.behance.net/bkeywebstudio">
                            <i className="fab fa-behance"></i>
                        </a>
                    </li>
                </ul>
            </nav>
            <MobileNavbarContainer/>
        </header>
    )
};
export default Header;