import React from 'react';

import './Post.scss';
import {NavLink} from "react-router-dom";


const Post = (props) => {
    if (props.loading) {
        return "LOADING...";
    }

    /*const imgCheck = (post) => {
        if (post.post_img === null) {
            return null;
        }
        return (
            <img src={post.post_img} alt={post.post_img}
                 className="Blog__postImg"/>
        )
    };*/
    if (props.post === null) {
        let refurl = window.location.pathname.split('/')[2];
        //let refurl = window.location.pathname.slice(1);
        console.log(refurl);

        const filterNewsById = props.posts.filter(post => String(post.id) === refurl);

        console.log(filterNewsById);
        if (filterNewsById.length === 0) {
            return <h2>Такой новости нет :(</h2>;
        }
        return (
            <div key={filterNewsById[0].id} className="Blog__postContainer">
                <div className="row">
                    <div className="col">
                        <div className="Blog__postTitle">{filterNewsById[0].post_title}</div>
                        {/*<div className="Blog__postImgWrapper">{imgCheck(filterNewsById[0])}</div>*/}
                        <div className="Blog__postContent"><div
                            dangerouslySetInnerHTML={{__html: filterNewsById[0].post_content}}/></div>
                        <div className="Blog__postDate">{filterNewsById[0].post_date} |
                            <NavLink to="/inspire" className="Blog__postNews"> Новости</NavLink> |
                            <span className="Blog__postAuthor"> Автор: </span>
                            {filterNewsById[0].post_author}</div>
                        <NavLink to="/inspire" className="Blog__postBackToBlog">Больше Вдохновения</NavLink>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <div key={props.post.id} className="Blog__postContainer">
            <div className="row">
                <div className="col">
                    <div className="Blog__postTitle">{props.post.post_title}</div>
                    {/*<div className="Blog__postImgWrapper">{imgCheck(props.post)}</div>*/}
                    <div className="Blog__postDate">{props.post.post_date} |
                        <NavLink to="/inspire" className="Blog__postNews"> Новости</NavLink> |
                        <span className="Blog__postAuthor">Автор: </span>
                        {props.post.post_author}</div>
                    <div className="Blog__postContent"><div
                        dangerouslySetInnerHTML={{__html: props.post.post_content}}/></div>
                    <NavLink to="/inspire" className="Blog__postBackToBlog">НАЗАД</NavLink>
                </div>
            </div>
        </div>
    )
};


export default Post;
