import React from 'react';
import App from "../App";
import {connect} from "react-redux";
import BlogListActions from "../redux/actions/blog-actions";
import HeaderListActions from "../redux/actions/header-actions";

class AppContainer extends React.Component {
    componentDidMount() {
        this.props.fetchPosts();
    }
    render() {
        const collapse = () => {
            return this.props.header.mobileMenu === "is-open" ? "display-none" : ""
        };

        return <App mobileActive={collapse}/>
    }
}


let mapStateToProps = (state) => {
    return {
        blogPage : state.blogPage,
        header : state.header
    }
};
let mapDispatchToProps = (dispatch) => {
    return {
        fetchPosts: () => {
            dispatch(BlogListActions.fetchPosts());
        },
        setMobileMenu: (active) => {
            dispatch (HeaderListActions.setMobileMenu(active));
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AppContainer);
