import React from 'react';

import './ServicesDev.scss';

import ipad from './img/website-ipad-iphone.png';

const ServicesDev = (props) => {
    return (
        <div className="ServicesDev">
            <div className="ServicesDev__row">
                <div className="ServicesDev__text_column">
                    <h2 className="ServicesDev__title">Разработка сайтов</h2>
                    <p className="ServicesDev__text"><span>Landing Page</span>
                        Одностраничный сайт, который представляет определенный товар или услугу, созданный для того,
                        чтобы достичь конечной цели - оформить заявку, заказать звонок, подписаться на рассылку и
                        прочее.
                    </p>
                    <p className="ServicesDev__text">
                        <span>Сайт-визитка</span>
                        Это лицо частного предпринимателя или компании, наиболее успешный способ представить услугу в
                        интернете - информативный, немногословный, привлекательный дизайн.
                    </p>
                    <p className="ServicesDev__text"><span>Корпоративный сайт</span>
                        Ресурс значительно расширен и дополнен по сравнению с лаконичным сайтом-визиткой, количество
                        страниц больше 15 - замечательная сетевая презентация бизнеса.
                    </p>
                </div>
                <div className="ServicesDev__img_column">
                    <img src={ipad} className="ServicesDev__img" alt="phone"/>
                </div>
            </div>
        </div>
    )
};

export default ServicesDev;