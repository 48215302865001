import React, {useEffect} from 'react';
import {NavLink} from "react-router-dom";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";

import './Bkey.scss';
import circle from './img/circle.svg';
import bkey from './img/bkey-black.svg';

gsap.registerPlugin(ScrollTrigger);

const Bkey = () => {
    useEffect(() => {
        gsap.from('.Bkey__img',{
            rotate: 120,
            scrollTrigger:{
                trigger: '.Bkey',
                start: "top 15%",
                end: "bottom 10%",
                toggleActions: 'restart complete reverse reset',
                scrub: 3,
                /*markers: true,*/
            },
        });
    })
    return (
        <div className="Bkey">
            <div className="Bkey__row">
                <div className="Bkey__text_column">
                    <p className="Bkey__text">Создание креативных проектов, воплощающих ваши идеи в реальность.</p>
                    <div className="Bkey__btn">
                        <NavLink className="Bkey__link" to="/services">Services</NavLink>
                    </div>
                </div>
                <div className="Bkey__img_column">
                    <img className="Bkey__titleImg" alt="BKey Веб-студия"
                         src={bkey}/>
                    <img className="Bkey__img" alt="BKey Веб-студия"
                         src={circle}/>
                </div>
            </div>
        </div>
    )
};

export default Bkey;
