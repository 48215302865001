import React from 'react';

import './Login.scss';

import PostsCRUD from "./PostsCRUD/PostsCRUD";

class Login extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            loggedIn: false
        };
        this.login = this.login.bind(this);
        this.onChange = this.onChange.bind(this);
    }
    login() {
        console.log("Login function");
    }
    onChange(e) {
        console.log(this.state);
        this.setState( {
            [e.target.name]: e.target.value
        });
    }

    render() {
        return (
            <div className={"Contact "}>
                <form>
                    <p>Логин: <input name="username" required onChange={this.onChange}/></p>
                    <p>Пароль: <input type="password" name="password" required onChange={this.onChange}/></p>
                    <p><input type="submit" value="login" onClick={this.login}/></p>
                </form>
                <div>
                    <PostsCRUD/>
                </div>
            </div>
        )
    }
}

export default Login;