import React from 'react';
import Post from "../components/Blog/Post/Post";
import {connect} from "react-redux";
import BlogListActions from "../redux/actions/blog-actions";


class PostContainer extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        return (
                <Post posts={this.props.blogPage.posts} post={this.props.blogPage.post} loading={this.props.blogPage.loading}/>
            )
    }
}

let mapStateToProps = (state) => {
    return {
        blogPage : state.blogPage
    }
};
let mapDispatchToProps = (dispatch) => {
    return {
        setPosts: (posts) => {
            dispatch(BlogListActions.setPosts(posts));
        },
        setPost: (post) => {
            dispatch(BlogListActions.setPost(post));
        },
        setLoading: (load) => {
            dispatch(BlogListActions.setLoading(load));
        },
        setCurrentPage: (qty) => {
            dispatch(BlogListActions.setCurrentPage(qty));
        },
        setPostPerPage: (qty) => {
            dispatch(BlogListActions.setPostPerPage(qty));
        },
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(PostContainer);