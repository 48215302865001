import React from 'react';
import {NavLink} from "react-router-dom";

import './Subject.scss';

import phrases from "./img/phrases.jpg";

class Subject extends React.Component {

    render() {
        return (
            <div className="Subject">
                <div className="Subject__row">
                    <div className="Subject__text_column">
                        <h1 className="Subject__title">Реализованные Проекты</h1>
                        <p className="Subject__text">Идеальный сайт не зависит от тематики и вида отрасли, для которого он создаётся. Самое главное - это уникальный дизайн, оптимизация и высокая производительность.</p>
                        <div className="Subject__btn">
                            <NavLink className="Subject__link" to="/projects">Вперёд к нашим проектам</NavLink>
                        </div>
                    </div>
                    <div className="Subject__img_column">
                        <img className="Subject__img" alt="BKey Веб-студия"
                             src={phrases}/>
                    </div>
                </div>
            </div>
        )
    };
}

export default Subject;