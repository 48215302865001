export const SET_MOBILE_MENU = 'SET-MOBILE-MENU';

const HeaderListActions = {
    setMobileMenu: active => ({
        type: SET_MOBILE_MENU,
        payload: active
    }),
};

export default HeaderListActions;
