import React from 'react';

import './ServicesSupport.scss';

import keyboard from './img/support-keyboard.svg';

const ServicesSupport = (props) => {
    return (
        <div className="ServicesSupport">
            <div className="ServicesSupport__row">
                <div className="ServicesSupport__text_column">
                    <h2 className="ServicesSupport__title">Поддержка</h2>
                    <p className="ServicesSupport__text"><span>Обновление информации на сайте</span>
                        Размещение новой информации на сайтах, редактирование текста, оптимизация изображений и видео для возможности размещения на сайте.
                    </p>
                    <p className="ServicesSupport__text"><span>Техническое обслуживание сайта</span>
                        Внесение изменений в существующие  модули, редизайн, создание новых сервисов, оптимизация работы.
                    </p>
                    <p className="ServicesSupport__text"><span>Обеспечение бесперебойной работы</span>
                        Обеспечение стабильной работы сайта, устранение неполадок, создание препятствий для вирусов и спама.
                    </p>
                </div>
                <div className="ServicesSupport__img_column">
                    <img src={keyboard} className="ServicesSupport__img" alt="phone"/>
                </div>
            </div>
        </div>
    )
};

export default ServicesSupport;