import React from 'react';
import {NavLink} from "react-router-dom";

import './Pagination.scss';

const Pagination = ({ postsPerPage, totalPosts, setCurrentPage, currentPage }) => {
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
        pageNumbers.push(i);
    }

    return (
        <nav className="Blog__pagination">
            <ul className="Blog__paginationItems">
                {pageNumbers.map(number => (
                    <li key={number} className="Blog__paginationItem">
                        <NavLink onClick={()=> {setCurrentPage(number); window.scrollTo(0, 0);}} to="/inspire/" className="Blog__paginationLink" activeClassName={(currentPage === number ? 'page-active' : 'page-notActive')} kto={number}>
                            {number}
                        </NavLink>
                    </li>
                ))}
            </ul>
        </nav>
    );
};

export default Pagination;
