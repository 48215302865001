import React, {useEffect} from 'react';
import {NavLink} from "react-router-dom";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";

import './ServicesSubject.scss';

import circle from "./img/design-circle.svg";
import square from "./img/website-square.svg";
import triangle from "./img/digital-marketing-triangle.svg";

gsap.registerPlugin(ScrollTrigger);

function ServicesSubject () {
    useEffect(() => {
    const tl = gsap.timeline();
    tl
        .to('.ServicesSubject__circle',{y: 274, x: 98,},"figures")
        .to('.ServicesSubject__square',{x: 134,y:-150,},"figures")
        .to('.ServicesSubject__triangle',{x:-232,y:-120},"figures");
    ScrollTrigger.create({
    animation: tl,
    trigger: '.ServicesSubject',
    start: "top 10%",
    end: "100% 100%",
    toggleActions: 'restart complete reverse reset',
    scrub: 3,
    //markers: true,
    });
})
        return (
            <div className="ServicesSubject">
                <div className="ServicesSubject__row">
                    <div className="ServicesSubject__text_column">
                        <h1 className="ServicesSubject__title">Услуги</h1>
                        <p className="ServicesSubject__text">Мы занимаемся дизайном и разработкой веб-сайтов, созданием
                            фирменного стиля компаний (Brand Identity), SEO-оптимизацией, контекстной рекламой в Google
                            и продвижением в социальных сетях (SMM) .
                        </p>
                        <div className="ServicesSubject__btn">
                            <NavLink className="ServicesSubject__link" to="/projects">Проекты</NavLink>
                        </div>
                    </div>
                    <div className="ServicesSubject__img_column">
                        <img className="ServicesSubject__circle" alt="BKey Веб-студия"
                             src={circle}/>
                        <img className="ServicesSubject__square" alt="BKey Веб-студия"
                             src={square}/>
                        <img className="ServicesSubject__triangle" alt="BKey Веб-студия"
                             src={triangle}/>
                    </div>
                </div>
            </div>
        )
}

export default ServicesSubject;
