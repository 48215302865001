import {SET_COUNTER, SET_COUNTER_NUM, SET_COUNTER_SPEED, SET_COUNTER_VALUE} from "../actions/counter-actions";

let initialState = {
    counter: false,
    num: 0,
    value: null,
    speed: null,
};

const counterReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_COUNTER: {
            return {...state, counter: action.payload}
        }
        case SET_COUNTER_NUM: {
            return {...state, num: action.payload}
        }
        case SET_COUNTER_VALUE: {
            return {...state, value: action.payload}
        }
        case SET_COUNTER_SPEED: {
            return {...state, speed: action.payload}
        }
        default:
            return state;
    }
};

export default counterReducer;
