import React from 'react';
import {connect} from "react-redux";
import CounterActions from "../redux/actions/counter-actions";
import AboutUs from "../components/Main/AboutUs/AboutUs";

class AboutUsContainer extends React.Component {

    render() {
        const setCounter = active => {
            return this.props.setCounter(active)
        };
        const setCounterNum = num => {
            return this.props.setCounterNum(num)
        };
        const setCounterValue = value => {
            return this.props.setCounterValue(value)
        };
        const setCounterSpeed = speed => {
            return this.props.setCounterSpeed(speed)
        };
        return <AboutUs setCounter={setCounter} setCounterNum={setCounterNum} setCounterValue={setCounterValue} setCounterSpeed={setCounterSpeed} counter={this.props.counter.counter}/>
    }
}

let mapStateToProps = (state) => {
    return {
        counter : state.counter
    }
};
let mapDispatchToProps = (dispatch) => {
    return {
        setCounter: (active) => {
            dispatch (CounterActions.setCounter(active));
        },
        setCounterNum: (num) => {
            dispatch (CounterActions.setCounterNum(num));
        },
        setCounterValue: (value) => {
            dispatch (CounterActions.setCounterValue(value));
        },
        setCounterSpeed: (speed) => {
            dispatch (CounterActions.setCounterSpeed(speed));
        },

    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AboutUsContainer);