import { createStore, combineReducers, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import blogReducer from "./reducers/blog-reducer";
import headerReducer from "./reducers/header-reducer";
import counterReducer from "./reducers/counter-reducer";
import contactReducer from "./reducers/contact-reducer";

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middleware = composeEnhancer(applyMiddleware(thunk));

let reducers = combineReducers({
    blogPage: blogReducer,
    header: headerReducer,
    counter: counterReducer,
    contact: contactReducer,
});

let store = createStore(reducers, middleware);

window.store = store;

export default store;