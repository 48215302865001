import {SET_CURRENT_PAGE, SET_LOADING, SET_POST, SET_POSTS, SET_POSTS_PER_PAGE} from "../actions/blog-actions";

let initialState = {
    loading: false,
    posts: [],
    error: null,
    post: null,
    currentPage: 1,
    postsPerPage: 10,
};

const blogReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_POSTS: {
            return {...state, posts: action.payload}
        }
        case SET_POST: {
            return {...state, post: action.payload}
        }
        case SET_LOADING: {
            return {...state, loading: action.payload}
        }
        case SET_CURRENT_PAGE: {
            return {...state, currentPage: action.payload}
        }
        case SET_POSTS_PER_PAGE: {
            return {...state, postsPerPage: action.payload}
        }
        default:
            return state;
    }
};



export default blogReducer;
