import React, {useEffect} from 'react';
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";

import './Themes.scss';
import goahead from "./img/go-ahead.svg";

gsap.registerPlugin(ScrollTrigger);

const Themes = () => {
    /*const eco = () => {
        const ecology = document.getElementById("eco");
        console.log(ecology);
        let el = ecology.getBoundingClientRect()
        console.log(el);
        window.scrollTo(0, (el.y));
        //ecology.scrollIntoView({block: "center", behavior: "smooth"});
    }*/
    useEffect(() => {
        gsap.to('.Themes__goImg',{
            xPercent: -10,
            scrollTrigger:{
                trigger: '.Themes__goImg',
                start: "-400 20%",
                end: "bottom 20%",
                toggleActions: 'restart complete reverse reset',
                scrub: 3,
                //markers: true,
            },
        });
    } )
        return (
            <div className="Themes">
                <div className="Themes__row">
                    <div className="Themes__go_column">
                        <img className="Themes__goImg" src={goahead} alt="Go Ahead"/>
                    </div>
                    <div className="Themes__column-4">
                        <div className="Themes__button">
                            <div className="Themes__link">ecology</div>
                        </div>
                    </div>
                    <div className="Themes__column-8">
                        <div className="Themes__button">
                            <div className="Themes__link">kitchen design</div>
                        </div>
                    </div>
                    <div className="Themes__column-8">
                        <div className="Themes__button">
                            <div className="Themes__link">interior design</div>
                        </div>
                    </div>
                    <div className="Themes__column-4">
                        <div className="Themes__button">
                            <div className="Themes__link">medicine</div>
                        </div>
                    </div>
                    <div className="Themes__column-4">
                        <div className="Themes__button">
                            <div className="Themes__link">industry</div>
                        </div>
                    </div>
                    <div className="Themes__column-8">
                        <div className="Themes__button">
                            <div className="Themes__link">construction</div>
                        </div>
                    </div>
                    <div className="Themes__column-12">
                        <div className="Themes__button">
                            <div className="Themes__link">furniture design</div>
                        </div>
                    </div>
                </div>
            </div>
        )
};

export default Themes;
