import {SET_MOBILE_MENU} from "../actions/header-actions";

let initialState = {
    mobileMenu: "",
};

const headerReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_MOBILE_MENU: {
            return {...state, mobileMenu: action.payload}
        }
        default:
            return state;
    }
};

export default headerReducer;
