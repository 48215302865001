import React from 'react';
import {NavLink} from "react-router-dom";

import './MobileNavbar.scss';

const MobileNavbar = ({mobileActive, setMobileMenu}) => {

    return (
        <div className="HeaderMobile">
            <button className={"HeaderMobile__btn " + mobileActive} id="burger"
                    onClick={() => mobileActive === "" ? setMobileMenu("is-open") : setMobileMenu("")}>
                <span className="HeaderMobile__btnDesign"></span>
            </button>
            <nav className={"HeaderMobile__nav " + mobileActive} role="navigation" id="main-nav">
                <ul className="HeaderMobile__navItems">
                    <li className="HeaderMobile__navItem">
                        <NavLink className={"HeaderMobile__navLink " + mobileActive} isActive={checkActive}
                                 onClick={() => setMobileMenu("")} to="/">Главная</NavLink>
                    </li>
                    <li className="HeaderMobile__navItem">
                        <NavLink className={"HeaderMobile__navLink " + mobileActive} onClick={() => setMobileMenu("")}
                                 to="/services">Услуги</NavLink>
                    </li>
                    <li className="HeaderMobile__navItem">
                        <NavLink className={"HeaderMobile__navLink " + mobileActive} onClick={() => setMobileMenu("")}
                                 to="/projects">Наши работы</NavLink>
                    </li>
                    <li className="HeaderMobile__navItem">
                        <NavLink className={"HeaderMobile__navLink " + mobileActive} onClick={() => setMobileMenu("")}
                                 to="/inspire">Интересное</NavLink>
                    </li>
                    <li className="HeaderMobile__navItem">
                        <NavLink className={"HeaderMobile__navLink " + mobileActive} onClick={() => setMobileMenu("")}
                                 to="/contact">Контакты</NavLink>
                    </li>
                </ul>
            </nav>
        </div>
    )
}
const checkActive = (match, location) => {
    //some additional logic to verify you are in the home URI
    if (!location) return false;
    const {pathname} = location;
    //console.log(pathname);
    return pathname === "/";
}
export default MobileNavbar;

/*
let burger = document.getElementById('burger'),
	 nav    = document.getElementById('main-nav');

burger.addEventListener('click', function(e){
	this.classList.toggle('is-open');
	nav.classList.toggle('is-open');
});
*/