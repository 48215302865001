import React from 'react';
import {NavLink} from "react-router-dom";

import './ReadyToStart.scss';

const ReadyToStart = () => {
    return (
        <div className="ReadyToStart">
            <div className="ReadyToStart__row">
                <div className="ReadyToStart__column">
                    <div className="ReadyToStart__button">
                        <NavLink to="/contact" className="ReadyToStart__link">Работать вместе</NavLink>
                    </div>
                    <NavLink to="/contact" className="ReadyToStart__title">Связаться с нами</NavLink>
                </div>
            </div>
        </div>
    )
};

export default ReadyToStart;
