import React, {useEffect} from 'react';

import './Services.scss';
import ServicesSubject from "./ServicesSubject/ServicesSubject";
import ServicesBranding from "./ServicesBranding/ServicesBranding";
import ServicesDev from "./ServicesDev/ServicesDev";
import ServicesPromotion from "./ServicesPromotion/ServicesPromotion";
import ServicesSupport from "./ServicesSupport/ServicesSupport";
import OurPartners from "../Main/OurPartners/OurPartners";
import ReadyToStart from "../Main/ReadyToStart/ReadyToStart";


function Services (props) {
        useEffect(() => {
            window.scrollTo(0, 0);
        });
        return (
            <div className={"Services " + props.mobileActive()}>
                <ServicesSubject/>
                <ServicesBranding/>
                <ServicesDev/>
                <ServicesPromotion/>
                <ServicesSupport/>
                <OurPartners/>
                <ReadyToStart/>
            </div>
        )
}

export default Services;
