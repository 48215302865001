import React from "react";
import { TweenLite} from "gsap";

import './SmoothScroll.scss';

export default class SmoothScroll extends React.Component {
    state = {
        height: window.innerHeight
    };

    ro = new ResizeObserver(elements => {
        for (let elem of elements) {
            const crx = elem.contentRect;
            //console.log(crx);
            this.setState({
                height: crx.height
            });
        }
    });

    componentDidMount() {
        if (!/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)) {
            window.addEventListener("scroll", this.onScroll);
            this.ro.observe(this.viewport);
        }
        //console.log(!/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent));
    }

    onScroll = () => {
        TweenLite.to(this.viewport, 1, {
            y: -window.pageYOffset,
            ease: 0.05,
        });
    };

    render() {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)) {
            return (
                <>
                    {this.props.children}
                </>
            )

        } else {
            return (
                <>
                    <div className="viewport" ref={ref => (this.viewport = ref)}>
                        {this.props.children}
                    </div>
                    <div
                        ref={ref => (this.fake = ref)}
                        style={{
                            height: this.state.height
                        }}
                    />
                </>
            );
        }
    }
}
