import React from 'react';
import axios from 'axios';

import './PostsCRUD.scss';
import {NavLink} from "react-router-dom";

class PostsCRUD extends React.Component {
    state = {
        posts: [],
        login: true,
    };
    componentDidMount() {
        window.scrollTo(0, 0);
        const fetchPosts = async () => {
            const res = await axios.get('http://127.0.0.1/api/posts');
            const posts = res.data;
            this.setState({ posts });
        };
        fetchPosts();
    }

    render() {
        if (this.state.login) {
            return (
                <div>
                    <NavLink to={"/inspire/"} className="PostsList__contentLink">СОЗДАТЬ НОВЫЙ ПОСТ</NavLink>
                    <form className={"PostsCRUD "} method="POST" action="http://localhost/api/addpost">
                        <label className="PostsCRUD__title">Заголовок:</label>
                        <input type="text" name="title"/>
                        <label className="PostsCRUD__text">Текст:</label>
                        <input type="text" name="text"/>
                        <label className="PostsCRUD__author">Автор:</label>
                        <input type="text" name="author"/>
                        <label className="PostsCRUD__img">
                            Загрузка Изображения:
                        </label>
                        <input type="file" name="photo" multiple accept="image/*,image/jpeg"/>
                        <input className="PostsCRUD__submit" type="submit" name="save" value="Отправить"/>
                    </form>
                    <ul className="PostsList__items">
                        {this.state.posts.map(post => (
                                <li key={post.id} className="PostsList__item">
                                    <div className="PostsList__imgWrapper"></div>
                                    <div className="PostsList__content">
                                        <h2 className="PostsList__contentTitle">{post.post_title}</h2>
                                        <div className="PostsList__contentDate">{post.post_date}</div>
                                        <div className="PostsList__contentText"
                                             dangerouslySetInnerHTML={{__html: post.post_content}}></div>
                                        <NavLink to={"/inspire/" + post.id}
                                                 className="PostsList__contentLink">РЕДАКТИРОВАТЬ</NavLink>
                                        <a href={"http://localhost/api/removepost/" + post.id}
                                           className="PostsList__contentLink">УДАЛИТЬ</a>
                                    </div>
                                </li>
                            )
                        )}
                    </ul>
                </div>
            )
        } else {
            return (null)
        }
    }
}


export default PostsCRUD;