import React from 'react';

import './ServicesPromotion.scss';
import marketing from './img/digital-marketing-circle.svg'
import gad from './img/gad-circle.svg'
import seo from './img/seo-circle.svg'
import smm from './img/smm-circle.svg'

const ServicesPromotion = (props) => {
    return (
        <div className="ServicesPromotion">
            <div className="ServicesPromotion__row">
                <div className="ServicesPromotion__img_column">
                    <img src={marketing} alt="ServicesPromotion" className="ServicesPromotion__marketing"/>
                    <img src={gad} alt="ServicesPromotion" className="ServicesPromotion__gad"/>
                    <img src={seo} alt="ServicesPromotion" className="ServicesPromotion__seo"/>
                    <img src={smm} alt="ServicesPromotion" className="ServicesPromotion__smm"/>
                </div>
                <div className="ServicesPromotion__text_column">
                    <h3 className="ServicesPromotion__title">Продвижение</h3>
                    <p className="ServicesPromotion__text"><span>SEO-оптимизация (продвижение сайта)</span>
                        Целый комплекс мероприятий, направленный на увеличение посещаемости ресурса потенциальными
                        потребителями товаров и услуг - повышение рейтинга сайта и его узнаваемости, улучшение репутацию
                        бренда.
                    </p>
                    <p className="ServicesPromotion__text"><span>Контекстная реклама Google</span>
                        С помощью простых и качественных объявлений Google Ads вы сможете охватить больше
                        заинтересованных пользователей в пределах определенного бюджета, увеличить количество посещений
                        веб-сайта, звонков и посещений магазина.
                    </p>
                    <p className="ServicesPromotion__text"><span>SMM продвижение в социальных сетях Facebook и Instagram</span>
                        SMM (social media marketing) — один из инструментов интернет-маркетинга, с помощью которого
                        можно привлечь клиентов из социальных сетей. Генерация и продвижение контента (постов).
                    </p>
                </div>
            </div>
        </div>
    )
};

export default ServicesPromotion;