import React from 'react';
//import Slider from "react-slick";
//import "slick-carousel/slick/slick.css";
//import "slick-carousel/slick/slick-theme.css";

import './OurPartners.scss';
import capital from './img/capital.svg'
import esp from './img/esp.svg'
import ertec from './img/ertec.svg'
import emika from './img/emika.svg'
import alensa from './img/alensa.svg'
import ha from './img/ha.svg'
import hcm from './img/hcm.svg'

const OurPartners = (props) => {
    /*const settings = {
        className: "slider variable-width",
        dots: false,
        infinite: true,
        speed: 1000,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        pauseOnHover: true,
    };*/
    return (
            <div className="OurPartners">
                <div className="OurPartners__row">
                    <div className="OurPartners__title_column">
                        <p className="OurPartners__subtitle">Бренды, с которыми мы двигаемся вперёд</p>
                        <h2 className="OurPartners__title">Нам Доверяют</h2>
                    </div>
                    <div className="OurPartners__items">
                        <div className="OurPartners__item"><a href="http://www.emika.com.ua/"><img className="OurPartners__img" src={emika} alt=""/></a></div>
                        <div className="OurPartners__item"><a href="https://www.facebook.com/alensa.design"><img className="OurPartners__img" src={alensa} alt=""/></a></div>
                        <div className="OurPartners__item"><a href="https://ecosintez.com.ua/"><img className="OurPartners__img" src={esp} alt=""/></a></div>
                        <div className="OurPartners__item"><a href="https://www.er-tec.com.ua/"><img className="OurPartners__img" src={ertec} alt=""/></a></div>
                        <div className="OurPartners__item"><a href="http://www.capital-build.com/"><img className="OurPartners__img" src={capital} alt=""/></a></div>
                        <div className="OurPartners__item"><a href="https://higharchitects.com/"><img className="OurPartners__img" src={ha} alt=""/></a></div>
                        <div className="OurPartners__item"><a href="https://hcm.clinic/"><img className="OurPartners__img" src={hcm} alt=""/></a></div>
                       {/* <Slider {...settings}><img src={emika} alt="Emika"/>
                            <img src={ertec} alt="ER-tec"/>
                            <img src={higharchitects} alt="HighArchitects"/>
                            <img src={capitalbuild} alt="Capital Build"/>
                            <img src={esp} alt="Ecosintez"/>
                        </Slider>*/}
                    </div>
                </div>
            </div>
    )
};

export default OurPartners;