import React, {useEffect} from 'react';
import {NavLink} from "react-router-dom";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";

import './AboutUs.scss';
import go from "./img/go-creative.svg";

gsap.registerPlugin(ScrollTrigger);

const AboutUs = () => {
    useEffect(() => {
        gsap.from('.AboutUs__goImg',{
            x: 200,
            scrollTrigger:{
                trigger: '.AboutUs',
                start: "-50% 20%",
                end: "bottom 20%",
                toggleActions: 'restart complete reverse reset',
                scrub: 1,
                //markers: true,
            },
        });
    })
        return (
            <div className="AboutUs">
                <div className="AboutUs__row">
                    <div className="AboutUs__go_column">
                        <img className="AboutUs__goImg" src={go} alt="Go Creative"/>
                    </div>
                    <div className="AboutUs__title_column">
                        <h3 className="AboutUs__title">Про Нас</h3>
                    </div>
                    <div className="AboutUs__text_column">
                        <div className="AboutUs__text">Мы - современная студия дизайна и разработки веб-сайтов. Основной
                            нашей деятельностью является разработка и дизайн веб-сайтов,
                            создание фирменного стиля компании, а также мы предоставляем
                            полный набор услуг в сфере интернет-маркетинга.</div>
                        <div className="AboutUs__button">
                            <NavLink to="/contacts" className="AboutUs__link">Детальнее</NavLink>
                        </div>
                    </div>
                </div>
            </div>
        )
};

export default AboutUs;
