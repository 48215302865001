import React from 'react';

import './Main.scss';

import Bkey from './Bkey/Bkey';
import AboutUsContainer from "./../../containers/aboutus-container";
import ProjectsDesc from './ProjectsDesc/ProjectsDesc';
import OurPartners from './OurPartners/OurPartners';
import ReadyToStart from './ReadyToStart/ReadyToStart';
import Service from "./Service/Service";

class Main extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <div className={"Main " + this.props.mobileActive()}>
                <Bkey/>
                <AboutUsContainer/>
                <Service/>
                <ProjectsDesc/>
                <OurPartners/>
                <ReadyToStart/>
            </div>
        )
    }
}

export default Main;
