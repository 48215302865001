import {SET_NAME} from "../actions/contact-actions";
import {SET_EMAIL} from "../actions/contact-actions";
import {SET_SUBJECT} from "../actions/contact-actions";
import {SET_MESSAGE} from "../actions/contact-actions";
import {SET_TOKEN} from "../actions/contact-actions";

let initialState = {
    name: '',
    email: '',
    subject: '',
    message: '',
    token: '',
};

const contactReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_NAME : {
            return {...state, name: action.payload};
        }
        case SET_EMAIL : {
            return {...state, email: action.payload};
        }
        case SET_SUBJECT : {
            return {...state, subject: action.payload};
        }
        case SET_MESSAGE : {
            return {...state, message: action.payload};
        }
        case SET_TOKEN : {
            return {...state, token: action.payload};
        }
        default:
            return state;
    }

};

export default contactReducer;