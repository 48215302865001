import React from 'react';
import { connect } from 'react-redux';
import Blog from '../components/Blog/Blog';
import BlogListActions from "../redux/actions/blog-actions";

class BlogContainer extends React.Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        //Get Current posts
        const indexOfLastPost = this.props.blogPage.currentPage * this.props.blogPage.postsPerPage;
        const indexOfFirstPost = indexOfLastPost - this.props.blogPage.postsPerPage;
        const currentPosts = (this.props.blogPage.posts.slice(indexOfFirstPost, indexOfLastPost).length === 0) ?
            this.props.blogPage.posts.slice(((1 * this.props.blogPage.postsPerPage) - this.props.blogPage.postsPerPage), (1 * this.props.blogPage.postsPerPage)) : this.props.blogPage.posts.slice(indexOfFirstPost, indexOfLastPost);

        const setCurrentPage = (qty) => {
            return this.props.setCurrentPage(qty)
        };

        const setPostsPerPage = (qty) => {
            return this.props.setPostsPerPage(qty)
        };
        //Collapse mobile menu
        const collapse = () => {
            return this.props.header.mobileMenu === "is-open" ? "display-none" : ""
        };
        return <Blog blogPage={this.props.blogPage} currentPosts={currentPosts} setCurrentPage={setCurrentPage} setPostsPerPage={setPostsPerPage} mobileActive={collapse}/>
    }
}

let mapStateToProps = (state) => {
    return {
        blogPage : state.blogPage,
        header : state.header
    }
};

let mapDispatchToProps = (dispatch) => {
    return {
        setPosts: (posts) => {
            dispatch(BlogListActions.setPosts(posts));
        },
        setPost: (post) => {
            dispatch(BlogListActions.setPost(post));
        },
        setLoading: (load) => {
            dispatch(BlogListActions.setLoading(load));
        },
        setCurrentPage: (qty) => {
            dispatch(BlogListActions.setCurrentPage(qty));
        },
        setPostsPerPage: (qty) => {
            dispatch(BlogListActions.setPostsPerPage(qty));
        },
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(BlogContainer);
