import React from 'react';
import {Route, Switch} from 'react-router-dom';

import './App.scss';

import Header from './components/Header/Header'
import Footer from './components/Footer/Footer'
import NotFound from "./components/NotFound";
import BlogContainer from "./containers/blog-container";
import PostContainer from "./containers/post-container";
import MainContainer from "./containers/main-container";
import WorkContainer from "./containers/work-container";
import ContactContainer from "./containers/contact-container";
import Login from "./components/Login/Login";
import SmoothScroll from "./components/SmoothScroll/SmoothScroll";
import ServicesContainer from "./containers/services-container";

function App(props) {
    /*let cursorMove = (e) => {
        let cursor = document.querySelector(".cursor");
        cursor.style.top = e.pageY + 'px';
        cursor.style.left = e.pageX + 'px';
    }*/
    return (
        <SmoothScroll>
        <div /*onMouseMove={cursorMove}*/ className="App">
            {/*<div className="cursor"></div>*/}
            <Header/>
            <Switch>
                <Route exact path="/" component={MainContainer}/>
                <Route path="/projects" component={WorkContainer}/>
                <Route path="/services" component={ServicesContainer}/>
                <Route exact path="/inspire/" component={BlogContainer}/>
                <Route path="/inspire/:id" component={PostContainer}/>
                <Route path="/contact" component={ContactContainer}/>
                <Route path="/login" component={Login}/>
                <Route path="*" component={NotFound}/>
            </Switch>
            <Footer mobileActive={props.mobileActive}/>
        </div>
        </SmoothScroll>
    );
}

export default App;
