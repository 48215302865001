import React from 'react';
import {NavLink} from "react-router-dom";

import './PostsList.scss';

const PostsList = (props) => {
    if (props.loading) {
        return <h2>Loading...</h2>
    }
    console.log(props.posts);
    const imgCheck = (post) => {
        if (post.post_img === null) {
            return null;
        }
        return (
            <img src={post.post_img} alt={post.post_img}
                 className="PostList__contentImg"/>
        )
    };
    return (
        <ul className="PostsList__items">
            {props.posts.map(post => (
                    <li key={post.id} className="PostsList__item">
                        <div className="PostsList__imgWrapper"><NavLink to={"/inspire/" + post.id}>{imgCheck(post)}</NavLink></div>
                        <div className="PostsList__content">
                            <h2 className="PostsList__contentTitle"><NavLink to={"/inspire/" + post.id} className="PostsList__contentTitleLink">{post.post_title}</NavLink></h2>
                            <div className="PostsList__contentDate">{post.post_date}</div>
                            {/*<div className="PostsList__contentText" dangerouslySetInnerHTML={{__html: post.post_content}}></div>*/}
                            <NavLink to={"/inspire/" + post.id} className="PostsList__contentLink">ПЕРЕЙТИ</NavLink>
                        </div>
                    </li>
                )
            )}
        </ul>
    );
};

export default PostsList;
