import React from 'react';
import {NavLink} from "react-router-dom";

import './Blog.scss';
import PostsList from "./PostsList/PostsList";
import Pagination from "./Pagination/Pagination";

import b from "./img/b.svg";
import inspiration from "./img/inspiration.svg"

class Blog extends React.Component {
    render() {
        return (
            <div className={"Blog " + this.props.mobileActive()}>
                <div className="Blog__row">
                    <div className="Blog__text_column">
                        <h1 className="Blog__title">Вдохновляемся</h1>
                        <p className="Blog__text">Иногда, очень сложно понять то, как должен будет выглядеть будущий
                            проект. Для этого каждому человеку, даже профессиональному дизайнеру, необходимо
                            постоянно мониторить новенькое, то что будет цеплять и то что определённо
                            вдохновит к чему-то невероятно креативному.
                        </p>
                        <p className="Blog__text">Здесь мы будем делиться с Вами интересной информацией в сфере
                            разработки
                            сайтов, актуальных тенденциях в дизайне, а также новостями из жизни нашей компании.
                        </p>
                        <div className="Blog__btn">
                            <a className="Blog__link" href="https://www.behance.net/bkeywebstudio">Больше вдохновления</a>
                        </div>
                    </div>
                    <div className="Blog__img_column">
                        <img className="Blog__img" alt="BKey Веб-студия"
                             src={b}/>
                    </div>
                    <div className="Blog__inspiration_column">
                        <img className="Blog__inspirationImg" src={inspiration} alt="Inspiration"/>
                    </div>
                    <div className="Blog__column">
                        <PostsList posts={this.props.currentPosts} loading={this.props.blogPage.loading}
                                   post={this.props.blogPage.post}/>
                        <Pagination postsPerPage={this.props.blogPage.postsPerPage}
                                    totalPosts={this.props.blogPage.posts.length}
                                    setCurrentPage={this.props.setCurrentPage}
                                    currentPage={this.props.blogPage.currentPage}/>
                    </div>
                </div>
            </div>
        )
    };
}

export default Blog;
