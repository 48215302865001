import React from 'react';
import {NavLink} from "react-router-dom";
import hoverEffect from 'hover-effect';

import './Projects.scss';

//import VanillaTilt from 'vanilla-tilt';
import ertec from './img/ertec.svg'
import ertec1 from './img/ertec1.jpg';
import ertec2 from './img/ertec2.jpg';
import emika from './img/emika.svg'
import emika1 from './img/emika1.jpg';
import emika2 from './img/emika2.jpg';
import capital from './img/capital.svg'
import capital1 from './img/cb1.jpg';
import capital2 from './img/cb2.jpg';
import esp from './img/esp.svg';
import esp1 from './img/esp1.jpg';
import esp2 from './img/esp2.jpg';
import ha from './img/ha.svg';
import ha1 from './img/ha1.jpg';
import ha2 from './img/ha2.jpg';
import alensa from './img/alensa.svg'
import alensa1 from './img/alensa1.jpg';
import alensa2 from './img/alensa2.jpg';
import hcm from './img/hcm.png';
import hcmlogo from './img/hcm.svg';
import distortion1 from './img/distortion1.jpg';


class Projects extends React.Component {
    componentDidMount() {
        /*VanillaTilt.init(document.querySelectorAll(".Project__item"), {
            max: 5,
            speed: 400,
            glare: true,
            scale: 1,
            reverse: false,
            'max-glare': 0.5,
        });*/
        /* eslint-disable */
        let myAnimation = new hoverEffect({
            parent: document.querySelector('.Project__ecologyImg'),
            intensity: 0.3,
            image1: ertec1,
            image2: ertec2,
            displacementImage: distortion1,
        });
        let myAnimation1 = new hoverEffect({
            parent: document.querySelector('.Project__industryImg'),
            intensity: 0.3,
            image1: esp2,
            image2: esp1,
            displacementImage: distortion1,
        });
        let myAnimation2 = new hoverEffect({
            parent: document.querySelector('.Project__buildingImg'),
            intensity: 0.3,
            image1: capital1,
            image2: capital2,
            displacementImage: distortion1,
        });
        let myAnimation3 = new hoverEffect({
            parent: document.querySelector('.Project__furnitureImg'),
            intensity: 0.3,
            image1: alensa1,
            image2: alensa2,
            displacementImage: distortion1,
        });
        let myAnimation4 = new hoverEffect({
            parent: document.querySelector('.Project__kitchenImg'),
            intensity: 0.3,
            image1: emika1,
            image2: emika2,
            displacementImage: distortion1,
        });
        let myAnimation5 = new hoverEffect({
            parent: document.querySelector('.Project__interiorImg'),
            intensity: 0.3,
            image1: ha1,
            image2: ha2,
            displacementImage: distortion1,
        });
        let myAnimation6 = new hoverEffect({
            parent: document.querySelector('.Project__clinicImg'),
            intensity: 0.3,
            image1: hcm,
            image2: hcm,
            displacementImage: distortion1,
        });
    }

    render() {
        return (
            <div className="Projects">
                <div className="Projects__row">
                    <div className="Projects__ecologyImg_column">
                        <div className="Project__ecologyImg"></div>
                        <div className="Project__ecologyImgText_column">
                            <img src={ertec} className="Project__ecologyImgLogo" alt="er-tec"/>
                            <p className="Project__ecologyImgText">Утилизация опасных отходов в Украине</p>
                            <a href='https://www.er-tec.com.ua/' className="Project__ecologyImgLink">Проект</a>
                        </div>
                    </div>
                    <div className="Projects__ecologyText_column">
                        <div className="Projects__ecologySubTitle">
                            <span className="Projects__ecologySubTitleItem">Веб-сайт</span>
                            <span className="Projects__ecologySubTitleItem">Приложение</span>
                            <span className="Projects__ecologySubTitleItem">Фирменный стиль</span>
                            <span className="Projects__ecologySubTitleItem">SMM</span>
                        </div>
                        <div className="Projects__ecologyTitle">ЭКОЛОГИЯ</div>
                        <div className="Projects__ecologyText">Создание адаптивного многостраничного сайта, создание фирменного стиля компании (дизайн логотипа, визитки, открытки, журнала, папки, брошюры, рекламных баннеров), запуск рекламы в Google Ads и ведение социальных сетей Facebook | Instagram. Создание приложения для сотрудников компании.</div>
                        <a href='https://www.er-tec.com.ua/' className="Projects__ecologyLink">Детальнее</a>
                    </div>
                    <div className="Projects__industryText_column">
                        <div className="Projects__industrySubTitle">
                            <span className="Projects__industrySubTitleItem">Веб-сайт</span>
                        </div>
                        <div className="Projects__industryTitle">БИЗНЕС И
                            ИНДУСТРИЯ</div>
                        <div className="Projects__industryText">Создание адаптивного многостраничного сайта на нескольких языках.</div>
                        <a href='https://ecosintez.com.ua/' className="Projects__industryLink">Детальнее</a>
                    </div>
                    <div className="Projects__industryImg_column">
                        <div className="Project__industryImg"></div>
                        <div className="Project__industryImgText_column">
                            <img src={esp} className="Project__industryImgLogo" alt="capital build"/>
                            <p className="Project__industryImgText">Компания Экосинтезполимер национальный производитель полиэтиленовой пленки и мешков.</p>
                            <a href='https://ecosintez.com.ua/' className="Project__industryImgLink">Проект</a>
                        </div>
                    </div>
                    <div className="Projects__buildingImg_column">
                        <div className="Project__buildingImg"></div>
                        <div className="Project__buildingImgText_column">
                            <img src={capital} className="Project__buildingImgLogo" alt="capital build"/>
                            <p className="Project__buildingImgText">Строительство и ремонт</p>
                            <a href='http://www.capital-build.com/' className="Project__buildingImgLink">Проект</a>
                        </div>
                    </div>
                    <div className="Projects__buildingText_column">
                        <div className="Projects__buildingSubTitle">
                            <span className="Projects__buildingSubTitleItem">Веб-сайт</span>
                            <span className="Projects__buildingSubTitleItem">Создание логотипа</span>
                        </div>
                        <div className="Projects__buildingTitle">СТРОИТЕЛЬСТВО</div>
                        <div className="Projects__buildingText">Создание адаптивного многостраничного сайта и логотипа компании.</div>
                        <a href='http://www.capital-build.com/' className="Projects__buildingLink">Детальнее</a>
                    </div>
                    <div className="Projects__furnitureText_column">
                        <div className="Projects__furnitureSubTitle">
                            <span className="Projects__furnitureSubTitleItem">SMM</span>
                        </div>
                        <div className="Projects__furnitureTitle">ДИЗАЙН МЕБЕЛИ</div>
                        <div className="Projects__furnitureText">Cоздание официальных страниц Instagram | Facebook. Ведение. Фирменный
                            дизайн страницы.</div>
                        <a href="https://www.facebook.com/alensa.design" className="Projects__furnitureLink">Детальнее</a>
                    </div>
                    <div className="Projects__furnitureImg_column">
                        <div className="Project__furnitureImg"></div>
                        <div className="Project__furnitureImgText_column">
                            <img src={alensa} className="Project__furnitureImgLogo" alt="alena sazonova"/>
                            <p className="Project__furnitureImgText">Создание дизайна кухонь и мебели</p>
                            <a href="https://www.facebook.com/alensa.design" className="Project__furnitureImgLink">Проект</a>
                        </div>
                    </div>
                    <div className="Projects__kitchenImg_column">
                        <div className="Project__kitchenImg"></div>
                        <div className="Project__kitchenImgText_column">
                            <img src={emika} className="Project__kitchenImgLogo" alt="emika"/>
                            <p className="Project__kitchenImgText">Производитель современных кухонь и мебели</p>
                            <a href="http://www.emika.com.ua/" className="Project__kitchenImgLink">Проект</a>
                        </div>
                    </div>
                    <div className="Projects__kitchenText_column">
                        <div className="Projects__kitchenSubTitle">
                            <span className="Projects__kitchenSubTitleItem">Веб-сайт</span>
                            <span className="Projects__kitchenSubTitleItem">Создание логотипа</span>
                            <span className="Projects__kitchenSubTitleItem">SMM</span>
                        </div>
                        <div className="Projects__kitchenTitle">КУХНИ</div>
                        <div className="Projects__kitchenText">Создание адаптивного многостраничного сайта и логотипа компании. Создание страниц Instagram | Facebook. Продвижение и реклама в социальных сетях.</div>
                        <a href="http://www.emika.com.ua/" className="Projects__kitchenLink">Детальнее</a>
                    </div>
                    <div className="Projects__interiorText_column">
                        <div className="Projects__interiorSubTitle">
                            <span className="Projects__interiorSubTitleItem">SMM</span>
                        </div>
                        <div className="Projects__interiorTitle">ДИЗАЙН ИНТЕРЬЕРА</div>
                        <div className="Projects__interiorText">Продвижение в социальных сетях. Ведение страниц Instagram | Facebook. Реклама.</div>
                        <a href="https://higharchitects.com/" className="Projects__interiorLink">Детальнее</a>
                    </div>
                    <div className="Projects__interiorImg_column">
                        <div className="Project__interiorImg"></div>
                        <div className="Project__interiorImgText_column">
                            <img src={ha} className="Project__interiorImgLogo" alt="Higharchitects"/>
                            <p className="Project__interiorImgText">Комплексные дизайнерские решения для жилья</p>
                            <a href="https://higharchitects.com/" className="Project__interiorImgLink">Проект</a>
                        </div>
                    </div>
                    <div className="Projects__clinicImg_column">
                        <div className="Project__clinicImg"></div>
                        <div className="Project__clinicImgText_column">
                            <img src={hcmlogo} className="Project__clinicImgLogo" alt="HCM Clinic"/>
                            <p className="Project__clinicImgText">Центр диагностики и лечения гипертрофической
                                кардиомиопатии </p>
                            <a href="https://www.hcm.clinic/" className="Project__clinicImgLink">Проект</a>
                        </div>
                    </div>
                    <div className="Projects__clinicText_column">
                        <div className="Projects__clinicSubTitle">
                            <span className="Projects__clinicSubTitleItem">Веб-сайт</span>
                            <span className="Projects__clinicSubTitleItem">Логотип</span>
                            <span className="Projects__clinicSubTitleItem">SEO</span>
                        </div>
                        <div className="Projects__clinicTitle">МЕДИЦИНА</div>
                        <div className="Projects__clinicText">Создание адаптивного многостраничного сайта и коррекция логотипа компании.
                            SEO-оптимизация сайта.</div>
                        <a href="https://www.hcm.clinic/" className="Projects__clinicLink">Детальнее</a>
                    </div>
                </div>
            </div>
        )
    };
}

export default Projects;
