import React from 'react';
import {connect} from "react-redux";
import HeaderListActions from "../redux/actions/header-actions";
import ContactListActions from "../redux/actions/contact-actions";
import Contact from "../components/Contact/Contact";
import axios from 'axios';



class ContactContainer extends React.Component {

    render() {
        const setMobileMenu = active => {
            return this.props.setMobileMenu(active)
        };
        const collapse = () => {
            return this.props.header.mobileMenu === "is-open" ? "display-none" : ""
        };
        const setName = text => {
            return this.props.setName(text);
        };
        const setEmail = text => {
            return this.props.setEmail(text);
        };
        const setSubject = text => {
            return this.props.setSubject(text);
        };
        const setMessage = text => {
            return this.props.setMessage(text);
        };
        const verifyCallback = (response) => {
            if(response) {
                console.log(response);
                this.props.setToken(response);
            }
        };

        const handleSubmit = (event) => {
            event.preventDefault();
            //стандартные настройки axios
            //axios.defaults.baseURL = 'https://api.example.com';
            //axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;

            // не устанавливать Content-Type, т.к. используется FormData
            axios.defaults.headers.post['Content-Type'] = false;

            //создаем данные формы
            const formData = new FormData();
            // добавить в formData значение 'name'=значение_поля_name
            formData.append('name', this.props.contact.name);
            formData.append('email', this.props.contact.email);
            formData.append('subject', this.props.contact.subject);
            formData.append('message', this.props.contact.message);
            formData.append('g-recaptcha-response', this.props.contact.token);

            axios.post('http://localhost/api/message', formData)
                .then(res=>{
                    console.log(res);
                    console.log(res.data.result);
                    if (res.data.result === 'success') {
                        alert("Спасибо ваше сообщение отправлено");
                        setName("");
                        setEmail("");
                        setSubject("");
                        setMessage("");
                        this.props.setToken("");
                        window.recaptchaInstance.reset();
                    } else {
                        alert("Извините что-то пошло не так");
                    }
                    //window.location = "/retrieve" //This line of code will redirect you once the submission is succeed
                })
        };

        return <Contact
            mobileActive={collapse}
            setMobileMenu={setMobileMenu}
            handleSubmit={handleSubmit}
            setName={setName}
            setEmail={setEmail}
            setSubject={setSubject}
            setMessage={setMessage}
            verifyCallback={verifyCallback}
            name={this.props.contact.name}
            email={this.props.contact.email}
            subject={this.props.contact.subject}
            message={this.props.contact.message}
        />
    }
}

let mapStateToProps = (state) => {
    return {
        header : state.header,
        contact: state.contact
    }
};
let mapDispatchToProps = (dispatch) => {
    return {
        setMobileMenu: (active) => {
            dispatch (HeaderListActions.setMobileMenu(active));
        },
        setName: (text) => {
            dispatch (ContactListActions.setName(text));
        },
        setEmail: (text) => {
            dispatch (ContactListActions.setEmail(text));
        },
        setSubject: (text) => {
            dispatch (ContactListActions.setSubject(text));
        },
        setMessage: (text) => {
            dispatch (ContactListActions.setMessage(text));
        },
        setToken: (response) => {
            dispatch (ContactListActions.setToken(response));
        },
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactContainer);