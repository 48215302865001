import React, {useEffect} from 'react';
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";

import './ServicesBranding.scss';

import palette from './img/color-palette-block.svg'
import logo from './img/logo-block.svg'
import brand from './img/tone-brand-block.svg'
import typography from './img/typography-block.svg'
import visual from './img/visual-block.svg'
gsap.registerPlugin(ScrollTrigger);

const ServicesBranding = (props) => {
    useEffect(() => {
        const tl = gsap.timeline();
        tl
            .from('.ServicesBranding__logo',{rotate: 180, y: 40, x: 140,},"go")
            .from('.ServicesBranding__brand',{rotate: 180, y: 40, x: 140,},"go")
            .from('.ServicesBranding__palette',{rotate: 180},"go")
            .from('.ServicesBranding__typography',{x:150,y:200,rotate: -180},"go")
            .from('.ServicesBranding__visual',{x:250,y: 340,rotate: 70},"go");
        ScrollTrigger.create({
            animation: tl,
            trigger: '.ServicesBranding',
            start: "top 80%",
            end: "90% 100%",
            toggleActions: 'restart complete reverse reset',
            scrub: 3,
            //markers: true,
        });
    })
    return (
        <div className="ServicesBranding">
            <div className="ServicesBranding__row">
                <div className="ServicesBranding__img_column">
                    <img src={logo} alt="ServicesBranding" className="ServicesBranding__logo"/>
                    <img src={brand} alt="ServicesBranding" className="ServicesBranding__brand"/>
                    <img src={palette} alt="ServicesBranding" className="ServicesBranding__palette"/>
                    <img src={visual} alt="ServicesBranding" className="ServicesBranding__visual"/>
                    <img src={typography} alt="ServicesBranding" className="ServicesBranding__typography"/>
                </div>
                <div className="ServicesBranding__text_column">
                    <h3 className="ServicesBranding__title">Брендинг</h3>
                    <p className="ServicesBranding__text">Создание фирменного стиля компании - это набор графических образов и правил их применения, определяющий стилистику  оформления информации о бренде на имиджевых носителях. Ими могут быть деловые
                        документы, макет сайта, фирменная и сувенирная продукция, любые рекламные материалы.
                    </p>
                    <p className="ServicesBranding__text">В  стандарты фирменного стиля входят цвета, шрифты, фирменное оформление и дополнительные  визуальные элементы.</p>
                </div>
            </div>
        </div>
    )
};

export default ServicesBranding;
