import React from 'react';

import './Contact.scss';
/*import ContactForm from "./ContactForm/ContactForm";*/
import GoogleMap from "./GoogleMap/Map";
import letswork from "./img/lets-work-together.svg"

class Contact extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        /*const setName = text => {
            return this.props.setName(text);
        };
        const setEmail = text => {
            return this.props.setEmail(text);
        };
        const setSubject = text => {
            return this.props.setSubject(text);
        };
        const setMessage = text => {
            return this.props.setMessage(text);
        };
        const verifyCallback = (response) => {
            if(response) {
                this.props.verifyCallback(response);
            }
        };
        const handleSubmit = (event) => {
            return this.props.handleSubmit(event);
        };*/
        return (
            <div className={"Contact " + this.props.mobileActive()}>
                <div className="Contact__row">
                    <div className="Contact__column">
                        <h1 className="Contact__title">Связаться с Нами</h1>
                        <p className="Contact__text">Если у вас есть задуманный проект, идеи или вы просто хотите
                            узнать
                            немного больше про нашу компанию - свяжитесь с нами и мы в скором времени реализуем
                            все ваши планы.</p>
                        <a className="Contact__link" href="tel:+380680674747"
                              data-hover="+380680674747">+380680674747</a>
                        <a className="Contact__link" href="mailto:info@bkey.com.ua"
                           data-hover="info@bkey.com.ua">info@bkey.com.ua</a>
                        <h2 className="Contact__followTitle">
                            Подписывайтесь на Нас:
                        </h2>
                        <a className="Contact__followLink" href="https://www.facebook.com/bkeywebstudio/" target="_blank" rel="noopener noreferrer"
                           title="Follow us on Facebook">
                            <i className="fab fa-facebook-square"></i>
                        </a>
                        <a className="Contact__followLink" href="https://instagram.com/bkeywebstudio/" target="_blank" rel="noopener noreferrer"
                           title="Follow us on Instagram">
                            <i className="fab fa-instagram"></i>
                        </a>
                        <a className="Contact__followLink" href="https://www.behance.net/bkeywebstudio" target="_blank" rel="noopener noreferrer"
                           title="Follow us on Behance">
                            <i className="fab fa-behance"></i>
                        </a>
                    </div>
                    <div className="Contact__img_column">
                        {/*<ContactForm
                            handleSubmit={handleSubmit}
                            setName={setName}
                            setEmail={setEmail}
                            setSubject={setSubject}
                            setMessage={setMessage}
                            verifyCallback={verifyCallback}
                            name={this.props.name}
                            email={this.props.email}
                            subject={this.props.subject}
                            message={this.props.message}
                        />*/}
                        <img className="Contact__img" alt="BKey Веб-студия"
                                 src={letswork}/>
                    </div>
                </div>
                <div className="Map__row">
                    <GoogleMap/>
                </div>
            </div>
        )
    }
}

export default Contact;
