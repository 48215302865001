import axios from 'axios';

export const SET_POSTS = 'SET-POSTS';
export const SET_POST = 'SET-POST';
export const SET_LOADING = 'SET-LOADING';
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';
export const SET_POSTS_PER_PAGE = 'SET_POST_PER_PAGE';


const BlogListActions = {
    setPosts: items => ({
        type: SET_POSTS,
        payload: items
    }),
    setPost: item => ({
        type: SET_POST,
        payload: item
    }),
    setLoading: load => ({
        type: SET_LOADING,
        payload: load
    }),
    setCurrentPage: qty => ({
        type: SET_CURRENT_PAGE,
        payload: qty
    }),
    setPostsPerPage: qty => ({
        type: SET_POSTS_PER_PAGE,
        payload: qty
    }),
    /*fetchPosts: () => dispatch => {
        axios.get('http://127.0.0.1/wp/wp-json/wp/v2/projects/').then(({ data }) => {
            dispatch(BlogListActions.setPosts(data));
        });
    },*/
    fetchPosts: () => dispatch => {
        const fetchPosts = async () => {
            dispatch(BlogListActions.setLoading(true));
            const res = await axios.get('https://bkey.com.ua/api/posts');

            dispatch(BlogListActions.setPosts(res.data));
            dispatch(BlogListActions.setLoading(false));
        };
        fetchPosts();
        console.log('fetch');
    },
};

/*export const setPostsActionCreator = (items) => ({type: SET_POSTS, payload: items});
export const setPostActionCreator = (item) => ({type: SET_POST, payload: item});
export const setLoadingActionCreator = (load) => ({type: SET_LOADING, payload: load});
export const setCurrentPageActionCreator = (qty) => ({type: SET_CURRENT_PAGE, payload: qty});
export const setPostsPerPageActionCreator = (qty) => ({type: SET_POSTS_PER_PAGE, payload: qty});*/

export default BlogListActions;
