import React from 'react';
import {connect} from "react-redux";
import HeaderListActions from "../redux/actions/header-actions";
import Main from "../components/Main/Main";

class MainContainer extends React.Component {

    render() {
        const setMobileMenu = active => {
            return this.props.setMobileMenu(active)
        };
        const collapse = () => {
            return this.props.header.mobileMenu === "is-open" ? "display-none" : ""
        };
        return <Main mobileActive={collapse} setMobileMenu={setMobileMenu} />
    }
}

let mapStateToProps = (state) => {
    return {
        header : state.header
    }
};
let mapDispatchToProps = (dispatch) => {
    return {
        setMobileMenu: (active) => {
            dispatch (HeaderListActions.setMobileMenu(active));
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(MainContainer);