import React, {useEffect} from 'react';

import './Work.scss';

import Projects from './Projects/Projects';
import ReadyToStart from "../Main/ReadyToStart/ReadyToStart";
import Subject from "./Subject/Subject";
import Themes from "./Themes/Themes";


function Work (props) {
        useEffect(() => {
                window.scrollTo(0, 0);
        });
        return (
            <div className={"Services " + props.mobileActive()}>
                <Subject/>
                <Themes/>
                <Projects/>
                <ReadyToStart/>
            </div>
        )
}

export default Work;
