import axios from 'axios';
import qs from 'qs';

export const SET_NAME = 'SET-NAME';
export const SET_EMAIL = 'SET-EMAIL';
export const SET_SUBJECT = 'SET-SUBJECT';
export const SET_MESSAGE = 'SET-MESSAGE';
export const SET_TOKEN = 'SET-TOKEN';

const ContactListActions = {
    setName: item => ({
        type: SET_NAME,
        payload: item
    }),
    setEmail: item => ({
        type: SET_EMAIL,
        payload: item
    }),
    setSubject: item => ({
        type: SET_SUBJECT,
        payload: item
    }),
    setMessage: item => ({
        type: SET_MESSAGE,
        payload: item
    }),
    setToken: response => ({
        type: SET_TOKEN,
        payload: response
    }),
    sendMessage: item => {
        //const data = {'bar': 123};
        const data = item;
        const options = {
            method: 'POST',
            headers: {'content-type': 'application/x-www-form-urlencoded'},
            data: qs.stringify(data),
            url: 'http://localhost:8080/api/contact',
        };
        axios(options).then(res => {
            console.log(res);
            console.log(res.data);
        });
        return 'norm';
    }

};
export default ContactListActions;